import React from 'react';
import { Typography } from '@material-ui/core';

export default [
  {
    name: 'Getting Started',
    data: [
      {
        question: 'What is ACEapp?',
        answer: (
          <div>
            {/* A social network platform exclusively dedicated to people of Asexual spectrum. We aim to increase visibility of ace peoples well as connect the community together. With thousands of Aces to date, meet, chat and more ACEapp is the first dating site for asexuals exclusively. We are the only reliable and most trusted platform for asexuals around the world—wherever you go, we’ll be there. On ACEapp, you can meet new people and make new friends everyday. ACEapp offers you so many powerful features as Swipe, Chat, Active now, bookmarks, filters and much more.
            <br />
            <br /> */}
            ACEapp is a social network platform exclusively dedicated to people who fall in the asexual spectrum. We aim to increase the visibility of asexuality and bring the ace community together. With thousands of aces to date, meet, chat and more, ACEapp is the first dating site for asexuals exclusively. Here on ACEapp, we provide the tools to help you interact with other. Users are able to browse, chat, bookmark, set match preferences among aces and much more!
            <br/>
            <br />
            {/* Swipe Right if you like someone, Swipe Left to pass. No stress. No rejection. Just swipe and send the first message and start chatting with your connections.
            <br />
            <br /> */}
            The idea is that you swipe right if you like someone and swipe left to skip. No stress. No rejection. You can send them a message and start chatting with other aces simply by swiping right to visit their profile!
            <br />
            <br />
            {/* Most Welcome to ACEapp—the first community of asexuals in the world. No need to scare or hesitate. This is your world, come out and meet with new people.
            <br /> */}
            To get started, download the ACEapp on <a href="https://apps.apple.com/us/app/aceapp-the-social-network/id1391046754" target="_blank" rel="noopener noreferrer">iOS</a> or <a href="https://play.google.com/store/apps/details?id=xyz.purush.ace&hl=en" target="_blank" rel="noopener noreferrer">Android</a>.
          </div>
        )
      },
      {
        question: 'Is ACEapp free?',
        answer: (
          <div>
            Yes. ACEapp is available to download for free on <a href="https://apps.apple.com/us/app/aceapp-the-social-network/id1391046754" target="_blank" rel="noopener noreferrer">App Store</a> (iOS) and <a href="https://play.google.com/store/apps/details?id=xyz.purush.ace&hl=en" target="_blank" rel="noopener noreferrer">Google Play Store</a> (Android).
          </div>
        )
      },
      {
        question: 'Can I use ACEapp anywhere in the world?',
        answer: (
          <div>
            ACEapp is a global online social networking platform and is available in 190+ countries. Download the ACEapp on <a href="https://apps.apple.com/us/app/aceapp-the-social-network/id1391046754" target="_blank" rel="noopener noreferrer">iOS</a> or <a href="https://play.google.com/store/apps/details?id=xyz.purush.ace&hl=en" target="_blank" rel="noopener noreferrer">Android</a>.
          </div>
        )
      },
      {
        question: 'What is the minimum age requirement?',
        answer: (
          <div>
            The minimum age requirement for ACEapp is 17 years old.
            <br />
            <br />
            If you have been blocked from downloading ACEapp from App Store or Google Play Store, it is because the birth date you entered indicates that you are under 17 years old.
          </div>
        )
      },
    ]
  },
  {
    name: 'Account and Profile',
    data: [
      {
        question: 'How do I create an ACEapp account?',
        answer: (
          <div>
            Welcome to ACEapp! To get started:
            <ol>
              <li>Download the ACEapp for <a href="https://apps.apple.com/us/app/aceapp-the-social-network/id1391046754" target="_blank" rel="noopener noreferrer">iOS</a> or <a href="https://play.google.com/store/apps/details?id=xyz.purush.ace&hl=en" target="_blank" rel="noopener noreferrer">Android</a>.</li>
              <li>Tap <b>Join Us</b>.</li>
              <li>Allow ACEapp to have access on all required permissions.</li>
              <li>Start baking cake!</li>
            </ol>
          </div>
        )
      },
      {
        question: 'How do I edit my profile?',
        answer: (
          <div>
            <Typography variant="h6" gutterBottom>
              <b>Bio</b>
            </Typography>
            Tap on the profile tab at the bottom of the home screen > tap the pencil icon or <b>Edit Info</b> on the right upper corner > select bio on Edit Profile screen > Update saved changes by tapping your username at upper left corner.
            <br />
            <br />
            <Typography variant="h6" gutterBottom>
              <b>Profile Photo</b>
            </Typography>
            To make a change to your profile photo:
            <ul>
              {/* <li>
                <b>Add</b>: Tap the profile tab at the bottom of the home screen > tap the pencil icon or <b>Edit Info</b> on the right corner > tap on profile photo to add a new photo
              </li>
              <li>
                <b>Delete</b>: Tap the profile icon at the bottom of the home screen > tap the pencil icon or <b>Edit Info</b> on the right corner > tap on red <b>X</b> icon next to next photo
              </li> */}
              <li>
                <b>Update</b>: Tap the profile tab at the bottom of the home screen > tap the pencil icon or <b>Edit Info</b> on the right corner > tap on profile photo to replace your current profile photo.
              </li>
            </ul>
            <Typography variant="h6" gutterBottom>
              <b>Age</b>
            </Typography>
            Tap on the profile tab at the bottom of the home screen > tap the pencil icon or <b>Edit Info</b> on the right corner > select age on Edit Profile screen > Update saved changes by tapping your username at upper left corner.
            <br />
            <br />
            <Typography variant="h6" gutterBottom>
              <b>Name</b>
            </Typography>
            Tap on the profile tab at the bottom of the home screen > tap the pencil icon or <b>Edit Info</b> on the right corner > change name on Edit Profile screen > Update saved changes by tapping your username at upper left corner.
            <br />
            <br />
            <Typography variant="h6" gutterBottom>
              <b>Asexuality Type</b>
            </Typography>
            Tap on the profile tab at the bottom of the home screen > tap the pencil icon or <b>Edit Info</b> on the right corner > select asexuality type on Edit Profile screen > Update saved changes by tapping your username at upper left corner,
            <br />
            <br />
            <Typography variant="h6" gutterBottom>
              <b>Gender</b>
            </Typography>
            Tap on the profile tab at the bottom of the home screen > tap the pencil icon or <b>Edit Info</b> on the right corner > select gender on Edit Profile screen > Update saved changes by tapping your username at upper left corner.
            <br />
            <br />
            <Typography variant="h6" gutterBottom>
              <b>Profession and Other Info</b>
            </Typography>
            Tap on the profile tab at the bottom of the home screen > tap the pencil icon or <b>Edit Info</b> on the right corner > change the field you want to update on Edit Profile screen > Update saved changes by tapping your username at upper left corner.
          </div>
        ),
      },
      {
        question: 'How do I set my search preferences?',
        answer: (
          <div>
            Bake cake is the main feature of the app where you can view and swipe on other users’ profile. To filter who you see and swipe, you can edit your Bake Cake Settings using the filters button in home screen.
            <br />
            <br />
            <Typography variant="h6" gutterBottom>
              <b>Maximum distance</b>
            </Typography>
            <li>
              Tap the "Filters" button located on top right corner of the home screen > update <b>Show users within</b>.
            </li>
            <br />
            <Typography variant="h6" gutterBottom>
              <b>Gender</b>
            </Typography>
            <li>
              Tap the "Filters" button located on top right corner of the home screen > update <b>Select user with Gender</b>. You can select multiple value.
            </li>
            <br />
            <Typography variant="h6" gutterBottom>
              <b>Age Range</b>
            </Typography>
            <li>
              Tap the "Filters" button located on top right corner of the home screen > update <b>Select users within age range</b>.
            </li>
            <br />
            <Typography variant="h6" gutterBottom>
              <b>Interested In</b>
            </Typography>
            <li>
              Tap the "Filters" button located on top right corner of the home screen > update <b>Select users who are interested in</b>.
            </li>
            <br />
            <Typography variant="h6" gutterBottom>
              <b>Looking For</b>
            </Typography>
            <li>
              Tap the "Filters" button located on top right corner of the home screen > update <b>Select users who are looking for</b>.
            </li>
            {/* <br />
            <Typography variant="h6" gutterBottom>
              <b>Location</b>
            </Typography>
            <li>
              If you want to change your location and match with people around the world just follow these steps:
            </li>
            <ol>
              <li>Drag your fingers right side on the home screen to open the navigation drawer</li>
              <li>Select Settings</li>
              <li>Tap <b>Update Location</b></li>
              <li>Select <b>Add a new location</b></li>
            </ol> */}
          </div>
        ),
      },
      {
        question: 'How do I update my location?',
        answer: (
          <div>
            If you want to change your location and match with people around the world:
            <ol>
              <li>Drag your finger right on the home screen to open the navigation drawer.</li>
              <li>Select "Settings".</li>
              <li>Tap "Update Location".</li>
              <li>Select "Add a new location".</li>
            </ol>
          </div>
        ),
      },
      {
        question: 'How do I delete my account?',
        answer: (
          <div>
            For security purposes, if you want to delete your account, you’ll need to do it directly on the app. Please note that you'll permanently lose all of your data, messages, bookmarks, and other information associated with your account.
            <ol>
              <li>Tap the profile icon at the top of the main screen.</li>
              <li>Scroll down and select <b>Delete Account</b>.</li>
            </ol>
            <b>Note</b>: Deleting the ACEapp application does not delete your account.<br />
            Keep in mind, if you want to use ACEapp again after deleting your account, you will have to create a new account.
          </div>
        ),
      },
      {
        question: 'How do I report someone?',
        answer: (
          <div>
            To report a user:
            <ol>
              <li>Drag right on the home screen and open navigation drawer.</li>
              <li>Select "Settings".</li>
              <li>Select “Chat with developer”.</li>
              <li>Send a screenshot of the profile you want to report and write the reason behind reporting.</li>
            </ol>
          </div>
        ),
      },
      {
        question: 'What does swiping left and swiping right mean?',
        answer: (
          <div>
            ACEapp is an open community which allows you to view profile of every user by its most powerful feature Bake Cake. Bake cake is the swiping feature on ACEapp.
            <br />
            <br />
            Swipe left means <b>you are not interested in the user</b>.<br />
            Swipe right means <b>you are interested and you want to view user’s profile or message the user</b>.<br />
            <br />
            <b>Note</b>: Swiping right does not send any notifications to the user you swiped. The user will not get any notifications until you message them.
          </div>
        ),
      },
      {
        question: 'Can I search for a specific person on ACEapp?',
        answer: 'No. There is no such option to search a user directly by their name or username. You can setup different filter conditions from the home screen and try to find different users with different location and interests.'
      },
      {
        question: 'How do I update my email address associated with my account?',
        answer: 'For security reasons, you cannot update your email address once you have done signed up successfully. You can delete your account and make a new account with a new email address.'
      },
      {
        question: 'How do I change my username?',
        answer: 'For security reasons, you cannot update your username once you have signed up successfully. You can delete your account and make a new account with a new available username.'
      },
      {
        question: 'How do I change my password?',
        answer: (
          <div>
            To change your password:
            <ol>
              <li>If you are logged in, logout of the app and go to home screen.</li>
              <li>Click on the “forgot password” button.</li>
              <li>Enter your registered email address.</li>
              <li>Open the email and click on the link sent by ACEapp to update the password.</li>
            </ol>
          </div>
        )
      }
    ]
  },
  {
    name: 'Messaging and Bake Cake',
    data: [
      {
        question: 'What is Bake Cake?',
        answer: (
          <div>
            ACEapp is an open community which allows you to view profile of every user by its most powerful feature <b>Bake Cake</b>. Bake cake is the swipe feature on ACEapp.
            <br />
            <br />
            Swipe left means you are not interested in the user.
            <br />
            <br />
            Swipe right means you are interested and you want to view user’s profile or you want to message the user.<br />
            <b>Note</b>: Swiping right does not send any notifications to the user you swiped. The user will not get any notifications until you message them.
          </div>
        ),
      },
      {
        question: 'What is filters in Bake cake?',
        answer: 'ACEapp allows you to set up you search preferences by using the filter button in top of home screen. You can setup different filters condition from home screen and try to find different users with different location and interests.'
      },
      {
        question: 'What is a Bookmark and how do I use it?',
        answer: (
          <div>
            ACEapp allows you to save users that you may be interested in. For example, if you like a user and you are not ready to send a message right away you can save the user in Bookmarks. There are couple of ways to bookmark a user:
            <ol>
              <li>You can click the "Bookmark" button on user's profile.</li>
              <li>You can click the star button on user's swipe card when on Bake Cake mode.</li>
              <li>To view all the saved profiles, tap on bookmark tab from the home screen.</li>
            </ol>
          </div>
        ),
      },
      {
        question: 'How do I remove someone from my Bookmarks?',
        answer: (
          <div>
            To delete a profile from bookmark:
            <ol>
              <li>Tap on the bookmark tab from the home screen.</li>
              <li>For iOS: swipe the bookmarked profile you want to delete.</li>
              <li>For android: long press the bookmarked profile you want to delete.</li>
              <li>Confirm and delete.x</li>
            </ol>
          </div>
        )
      },
      {
        question: 'How do I send a message to someone?',
        answer: (
          <div>
            To message a user you like:
            <ol>
              <li>Swipe right on the user to view their profile.</li>
              <li>Tap on the "Message" button.</li>
              <li>Write your first message to the user and you can always access the chat from your inbox tab.</li>
            </ol>
          </div>
        )
      },
      {
        question: 'How do I block someone?',
        answer: (
          <div>
            To block a user:
            <ol>
              <li>Tap on the inbox tab on the home screen and open your inbox.</li>
              <li>Go to the chat of the user you want to block.</li>
              <li>Click ‘Block’ button on the left top corner of the chat screen.</li>
            </ol>
          </div>
        )
      },
      {
        question: 'How do I delete a chat conversation in ACEapp inbox?',
        answer: (
          <div>
            To delete chat conversation with a user:
            <ol>
              <li>Tap on the inbox tab on the home screen to open your inbox.</li>
              <li>For iOS: swipe the chat you want to delete.</li>
              <li>For android: long press the chat you want to delete.</li>
            </ol>
          </div>
        )
      }
    ],
  }
];

/* eslint-disable */
/**
 *
 * HelpPage
 *
 */

import React from 'react';
// import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom' 
import { createMuiTheme, withStyles, makeStyles } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import grey from '@material-ui/core/colors/grey';

import MediaBreakpoint from '../../components/MediaBreakpoint';

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText('#000'),
    backgroundColor:'#000',
    '&:hover': {
      backgroundColor: grey[900],
    },
  },
}))(Button);

export function HelpPage() {
  return (
    <div
      style={{
        position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%'
      }}
    >
      <MediaBreakpoint name="desktop">
        <Typography variant="h4" align='center'>Hi, how can we help?</Typography><br />
        <div style={{ display: 'flex', justifyContent: 'space-evenly'}}>
          <Link to="/help/Guide-To-ACEapp" style={{ textDecoration: 'none', width: '33%' }}>
            <Button size="large" variant="contained" color="primary" fullWidth>
              Guide to AceApp
            </Button>
          </Link>
          <Link to="/help/Troubleshooting" style={{ textDecoration: 'none', width: '33%' }}>
            <Button size="large" variant="contained" color="secondary" fullWidth>
              TroubleShooting
            </Button>
          </Link>
          <Link to="/help/Safety-security-privacy" style={{ textDecoration: 'none', width: '33%' }}>
            <ColorButton variant="contained" color="primary" size="large" fullWidth>
              Safety, Security, and Privacy
            </ColorButton>
          </Link>
        </div>
      </MediaBreakpoint>
      <MediaBreakpoint name="phone">
        <Typography variant="h5" align='center'>Hi, how can we help?</Typography><br />
        <Link to="/help/Guide-To-ACEapp" style={{ textDecoration: 'none' }}>
            <Button style={{margin: '1% 0'}} size="large" variant="contained" color="primary" fullWidth>
              Guide to AceApp
            </Button>
          </Link>
          <Link to="/help/Troubleshooting" style={{ textDecoration: 'none' }}>
            <Button style={{margin: '1% 0'}} size="large" variant="contained" color="secondary" fullWidth>
              TroubleShooting
            </Button>
          </Link>
          <Link to="/help/Safety-security-privacy" style={{ textDecoration: 'none', width: '33%' }}>
            <ColorButton style={{margin: '1% 0'}} size="large" variant="contained" color="primary" fullWidth>
              Safety, Security, and Privacy
            </ColorButton>
          </Link>
      </MediaBreakpoint>
    </div>
  );
}

// HelpPage.propTypes = {
// };

export default HelpPage;

import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import grey from '@material-ui/core/colors/grey';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import './App.css';
import HelpPage from './containers/HelpPage';
import GuidePage from './containers/GuidePage';
import TroubleshootPage from './containers/TroubleshootPage';
import SafeSecurePage from './containers/SafeSecurePage';

const theme = createMuiTheme({
  palette: {
    primary: { main: purple[400] },
    secondary: { main: grey[600] },
    companyBlue: {
      backgroundColor: '#65CFE9',
      color: '#fff',
    },
    companyRed: { 
        backgroundColor: '#E44D69',
        color: '#000',
    },
    accent: { 
        backgroundColor: purple[500], 
        color: '#000',
    },
  },
});

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <AppBar position="static">
          <Toolbar variant="dense">
            <IconButton edge="start" color="inherit" aria-label="Menu">
              <MenuIcon />
            </IconButton>
            <Typography variant="h6">
              ACEapp
            </Typography>
          </Toolbar>
        </AppBar>
          {/* <Route exact path="/" component={HomePage} /> */}
          <Route exact path="/" component={HelpPage} />
          <Route exact path="/help" component={HelpPage} />
          <Route exact path="/help/Guide-To-ACEapp" component={GuidePage} />
          <Route exact path="/help/Troubleshooting" component={TroubleshootPage} />
          <Route exact path="/help/Safety-security-privacy" component={SafeSecurePage} />
      </Router>
    </MuiThemeProvider>
  );
}

export default App;

/**
 *
 * FAQList
 * give a data that has questions and answers as a prop and this component will
 * render them in a list form and each question can be expand to view answer
 *
 */

import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import MediaBreakpoint from '../MediaBreakpoint';

function FAQList(props) {
  const { faqData } = props;
  return (
    <div style={{ marginBottom: '4%'}}>
      {faqData.map(({ name, data }) => (
        <div key={name}>
          <Typography variant="h5" align="center" color="primary" gutterBottom style={{ marginTop: '4%' }}>{name}</Typography>
            {data.map(({ question, answer }) => (
              <div key={question}>
                <MediaBreakpoint name="desktop">
                  <div style={{ margin: '0 15%' }}>
                    <ExpansionPanel key={question}>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>{question}</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography color="secondary" variant="subtitle2">
                          {answer}
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
                  </MediaBreakpoint>

                <MediaBreakpoint name="phone">
                  <div style={{ margin: '0 2% 0 2%' }}>
                    <ExpansionPanel key={question}>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>{question}</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography color="secondary" variant="caption">
                          {answer}
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
                </MediaBreakpoint>
              </div>
            ))}
          </div>
        ))}
    </div>
  );
}

FAQList.propTypes = {
  faqData: PropTypes.array.isRequired,
};

export default FAQList;

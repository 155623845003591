import React from 'react';

// let b = 
// {
//   question: '',
//   answer: '',
// };

export default [
  {
    name: 'Facing issues in login',
    data: [
      {
        question: 'I am not able to login',
        answer: (
          <div>
            If you're experiencing a technical problem or seeing an error message upon login, please know that this type of issue is usually temporary.
            <br />
            <br />
            There are definitely some things you can try to solve the issue!
            <ul>
              <li>Make sure you have a strong internet connection, or try switching between Wi-Fi and mobile data to gauge whether or not the problem is related to your connection</li>
              <li>Check the App Store or Google Play Store to see if there's a new version of the app available for download</li>
              <li>Already using the latest version? Try deleting the app and redownloading it - this won't affect any of your account info, matches, or messages</li>
            </ul>
            If the problem persists, we may be experiencing some service degradation and ask that you try again at a later time or send a mail to use on support@aceapp.info
          </div>
        ),
      },
      {
        question: 'I forgot my username?',
        answer: 'If you forget your username then send a mail to support@aceapp.info and write the issue. Also write your registered email address.',
      },
      {
        question: 'I forgot my password?',
        answer: (
          <div>
            To change your password:
            <ol>
              <li>If you are logged in, logout of the app and go to home screen.</li>
              <li>Click on the “forgot password” button.</li>
              <li>Enter your registered email address.</li>
              <li>Open the email and click on the link sent by ACEapp to update the password.</li>
            </ol>
          </div>
        ),
      },
      {
        question: 'How do I update my email address associated with my account?',
        answer: 'For security reasons, you cannot update your email address once you have done signed up successfully. You can delete your account and make a new account with a new email address'
      }
    ],
  },
  {
    name: 'Facing issues in Bake Cake (Swipe Feature)',
    data: [
      {
        question: 'I’m not seeing any profile on swipe',
        answer: (
          <div>
            Tap on "Filters" button on the upper right corner of the home screen and try setting distance filter conditions.
            <br />
            <br />
            If the problems persists: There are definitely some things you can try to solve the issue!
            <ul>
              <li>Make sure you have a strong internet connection, or try switching between Wi-Fi and mobile data to gauge whether or not the problem is related to your connection.</li>
              <li>Check the App Store or Google Play Store to see if there's a new version of the app available for download.</li>
              <li>Already using the latest version? Try deleting the app and redownloading it - this won't affect any of your account info, matches, or messages.</li>
            </ul>
            If the problem persists, we may be experiencing some service degradation and ask that you try again at a later time or send a mail to use on support@aceapp.info.
          </div>
        ),
      },
      {
        question: 'I see same profiles again and again even though I have swiped them left',
        answer: 'The profiles shown current match with your set preferences. Tap on "Filters" button on the upper right corner of the home screen and try setting distance filter conditions.',
      },
      {
        question: 'The profiles I see are far away.',
        answer: 'Tap on "Filters" button on the upper right corner of the home screen and try setting distance radius.',
      },
      {
        question: 'The profiles I see don’t match the filters condition I set up.',
        answer: 'This can be a potential bug. Please mail us on support@aceapp.info.',
      },
    ],
  },
  {
    name: 'Facing issue with my profile and settings.',
    data: [
      {
        question: 'I can’t delete my profile.',
        answer: 'This can be a potential bug. Please mail us on support@aceapp.info.',
      },
      {
        question: 'My profile information is wrong.',
        answer: 'You can always edit your profile. Please refer "How do I edit my profile?".',
      },
      {
        question: 'I can’t update my bio.',
        answer: 'This can be a potential bug. Please mail us on support@aceapp.info.',
      },
      {
        question: ' I can’t update my profile photo.',
        answer: 'This can be a potential bug. Please mail us on support@aceapp.info.',
      },
    ],
  },
  {
    name: 'Facing issues updating my location',
    data: [
      {
        question: 'I am not able to update my location.',
        answer: (
          <div>
            In order to use ACEapp, you'll need to grant access to your device's location.
            <br />
            <br />
            <b>On iPhone:</b> go to your iOS settings > ACEapp > Location
            <br />
            <b>On Android:</b> go to your phone’s Settings > Apps > ACEapp > Permissions > Location
            <br />
            <br />
            If problem persists please mail us on support@aceapp.info.
          </div>
        )
      }
    ],
  },
]
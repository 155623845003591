import React from 'react';

// let b = 
// {
//   question: '',
//   answer: '',
// };

export default [
  {
    name: 'Reporting a Safety Concert / Ticket',
    data: [
      {
        question: 'I want to report about a fake or impersonate profile.',
        answer: (
          <div>
            To report a user please mail us on support@aceapp.info.
            <br />
            <br />
            To report the user on application:
            <ol>
              <li>Drag your finger right on the home screen to open the navigation drawer.</li>
              <li>Select "Settings".</li>
              <li>Select “Chat with developer”.</li>
              <li>Send a screenshot of the profile you want to report and write the reason behind reporting.</li>
            </ol>
          </div>
        ),
      },
      {
        question: ' I want to report about an abusive profile.',
        answer: (
          <div>
            To report a user please mail us on support@aceapp.info.
            <br />
            <br />
            To report the user on application:
            <ol>
              <li>Drag your finger right on the home screen to open the navigation drawer.</li>
              <li>Select "Settings".</li>
              <li>Select "Chat with developer”.</li>
              <li>Send a screenshot of the profile you want to report and write the reason behind reporting.</li>
            </ol>
          </div>
        ),
      },
      {
        question: 'I want to report about a scam happening on the app.',
        answer: (
          <div>
            To report a user please mail us on support@aceapp.info.
            <br />
            <br />
            To report the user on application:
            <ol>
              <li>Drag your finger right on the home screen to open the navigation drawer.</li>
              <li>Select "Settings".</li>
              <li>Select "Chat with developer".</li>
              <li>Send the screenshot of the profile you want to report and write the reason behind reporting.</li>
            </ol>
          </div>
        ),
      },
      {
        question: 'I want to report about something that happened on the app.',
        answer: (
          <div>
            To report a user please mail us on support@aceapp.info.
            <br />
            <br />
            To report the user on application:
            <ol>
              <li>Drag your finger right on the home screen to open the navigation drawer.</li>
              <li>Select "Settings".</li>
              <li>Select "Chat with developer".</li>
              <li>Send a screenshot of the profile you want to report and write the reason behind reporting.</li>
            </ol>
          </div>
        ),
      },
    ],
  },
  {
    name: 'Security',
    data: [
      {
        question: 'I think my account is hacked.',
        answer: (
          <div>
            If you notice suspicious activity on your account, such as messages you haven't sent or photos you haven't uploaded, there are a few things you can do to ensure that your account is secure.
            <ol>
              <li>Update your password by using "forgot password" option.</li>
              <li>Mail us on support@aceapp.info.</li>
            </ol>
          </div>
        ),
      }
    ],
  }
]
/**
 *
 * TroubleshootPage
 *
 */

import React from 'react';
import { Link } from 'react-router-dom' 
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';
import Chip from '@material-ui/core/Chip';

import faqSSP from './faqSSP';
import FAQList from '../../components/FAQList';

function TroubleshootPage() {
  return (
    <div>
      <Breadcrumbs style={{ margin: '1% 0 0 1%' }}>
        <Link to="/help" style={{ textDecoration: 'none' }}><Chip style={{height: 24}} label="Help" /></Link>
        <Chip style={{height: 24}} label="Guide to ACEapp" />
      </Breadcrumbs>
      <FAQList faqData={faqSSP} />
    </div>
  );
}

TroubleshootPage.propTypes = {};

export default TroubleshootPage;
